<template>
  <div class="home-five">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.top_1.one }}</span>
      <span class="line"></span>
    </div>
    <div class="stitle">{{ langvalue.section4_1.one }}</div>
    <div class="detail">
      <div class="itemlist">
        <div class="item">
          <div class="value">
            {{
              type == 'English'
                ? langvalue.section4_1.two.substr(0, 4)
                : langvalue.section4_1.two.substr(
                    langvalue.section4_1.two.length - 4,
                    4
                  )
            }}
          </div>
          <div class="itemtext">
            {{
              type == 'English'
                ? langvalue.section4_1.two.substr(
                    4,
                    langvalue.section4_1.two.length
                  )
                : langvalue.section4_1.two.substr(
                    0,
                    langvalue.section4_1.two.length - 4
                  )
            }}
          </div>
        </div>
        <div class="item">
          <div class="value">
            {{
              type == 'English'
                ? langvalue.section4_1.three.substr(0, 4)
                : langvalue.section4_1.three.substr(
                    langvalue.section4_1.three.length - 4,
                    4
                  )
            }}
          </div>
          <div class="itemtext">
            {{
              type == 'English'
                ? langvalue.section4_1.three.substr(
                    4,
                    langvalue.section4_1.three.length
                  )
                : langvalue.section4_1.three.substr(
                    0,
                    langvalue.section4_1.three.length - 4
                  )
            }}
          </div>
        </div>
        <div class="item">
          <div class="value">
            {{
              type == 'English'
                ? langvalue.section4_1.four.substr(0, 4)
                : langvalue.section4_1.four.substr(
                    langvalue.section4_1.four.length - 4,
                    4
                  )
            }}
          </div>
          <div class="itemtext">
            {{
              type == 'English'
                ? langvalue.section4_1.four.substr(
                    4,
                    langvalue.section4_1.four.length
                  )
                : langvalue.section4_1.four.substr(
                    0,
                    langvalue.section4_1.four.length - 4
                  )
            }}
          </div>
        </div>
      </div>
      <div class="stitle">{{ langvalue.section4_1.five }}</div>
      <div class="stitle2">{{ langvalue.section4_1.six }}</div>
      <div class="video">
        <video
          id="video"
          class="videoplay"
          controls
          src="https://resources.wukongbc.com/video/home.mp4"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    const type = ref(langtype)
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))

    const playvideo = () => {
      document.getElementById('video').play()
    }
    const jiankongvideo = () => {
      document.getElementById('video').play()
    }
    watch(
      () => store.state.home.type,
      newVal => {
        langvalue.value = returnLanguage(newVal)
        type.value = newVal
      },
      { immediate: true }
    )
    return { langvalue, playvideo, jiankongvideo, type }
  }
}
</script>
<style scoped lang="less">
.home-five {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 70px;
  background: #EAEBF0;;
}
.title {
  font-size: 40px;
  // font-weight: bold;
  color: #100e34;
  font-family: 'PingFang SC';
  font-size: 40px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }
  .text {
    margin: 0 10px;
    // 加粗
    // font-weight: bold;
  }
}
.stitle {
  color: #141c41;
  font-weight: 400;
  font-size: 22px;
  margin-top: 20px;
}
.stitle2 {
  color: #141c41;
  font-weight: 400;
  font-size: 20px;
  margin-top: 68px;
}
.detail {
  background: url('../../../assets/images/video_bg.png') no-repeat;
  background-size: 100% 100%;
  width: 1240px;
  font-size: 20px;
  font-weight: 300;
  margin: 20px auto;
  padding: 20px 0;
  .itemlist {
    display: flex;
    .item {
      width: calc(33.3% - 20px);
      .value {
        font-family: 'PingFang SC';
        font-size: 80px;
        color: #141c41;
        font-weight: 400;
      }
      .itemtext {
        color: #141c41;
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}
.video {
  width: 700px;
  height: 350px;
  margin: 20px auto;
  position: relative;
  .videoplay {
    position: absolute;
    width: 700px;
    height: 350px;
    left: 0;
    top: 0;
    z-index: 2;
  }
}
</style>
